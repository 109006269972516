import React from 'react';

// Components
import Layout from '../../components/layout';
import FAQ from '../../components/resources/faq';
import Contact from '../../components/sections/contact';

import routes from '../../constants/routes';

const FAQPage = () => {
    return (
        <Layout slug={routes.faq} title="Frequently Asked Questions">
            <FAQ />
            <div className="copy-width">
                <Contact>
                    <h3>Got a Question We Didn't Answer?</h3>
                    <p>Send us a shout! We're happy to work with you to answer any questions you may have about working on your dream home.</p>
                </Contact>
            </div>
        </Layout>
    );
};

export default FAQPage;
