import React from 'react';

import * as styles from '../../styles/modules/faq.module.scss';

const FAQ = () => {
    return (
        <div className={styles.faq}>
            <div className="copy-width">
                <h1>Frequently Asked Questions</h1>
                <div className={styles.faqItem}>
                    <h2>What types of projects will Alouf Custom Builders take on?</h2>
                    <p>We build new homes, remodel or add onto existing homes, and build garages.</p>
                </div>
                <div className={styles.faqItem}>
                    <h2>Do I need to have my home or floorplans before I contact Alouf Custom Builders?</h2>
                    <p>Not at all! If you don't already have your plans, we will help you put together a design that meets your needs and desires. Once you're happy with the general layout we've created, we'll set you up with one of our draftsmen or architects to finalize everything.</p>
                </div>
                <div className={styles.faqItem}>
                    <h2>Am I limited to a portfolio of plans that Alouf Custom Builders has?</h2>
                    <p>No! With Alouf Custom Builders, there are no limits to what you can build (beyond what's physically possible). we can use a plan you have drawn up yourself, one you have found online, or work with an architect to design the perfect plan for you. The sky is the limit!</p>
                </div>
                <div className={styles.faqItem}>
                    <h2>Is the process of building a custom home difficult to go through?</h2>
                    <p>Not at all. Is it a lot of work? Yes. You'll have a lot of decisions to make. On the other hand, you will derive a lot of fun and satisfaction from knowing you had a hand in building your own home &ndash; that every single decision, from the overall design of the home to the color of the light switches, was made by you.</p>
                </div>
                <div className={styles.faqItem}>
                    <h2>How long will the build process take for a new home?</h2>
                    <p>From the time we break ground, it typically takes six to nine months to build a new home.</p>
                </div>
                <div className={styles.faqItem}>
                    <h2>How long will the build process take to remodel or add onto an existing home?</h2>
                    <p>It depends on the situation. In some cases, such as simple kitchen remodel, the process may take a month or two, whereas a more extensive addition make take longer.</p>
                </div>
                <div className={styles.faqItem}>
                    <h2>What is the first step?</h2>
                    <p>Give us a call! We will set up a meeting to get to know each other and go over your wishlist. From there we will guide you through the process of building your dream home, one step at a time!</p>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
